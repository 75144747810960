import React from 'react';
import { graphql } from 'gatsby';

import { HackathonPage } from 'components/Hackathon';

export default function PageHack0(props) {
  return <HackathonPage {...props} />;
}

export const query = graphql`
query HackathonPageQuery {
  strapiHackathonSignupPage {
    blade1_body {
      data {
        blade1_body
      }
    }
    blade1_title
    blade2_body {
      data {
        blade2_body
      }
    }
    blade2_title
    blade3_body {
      data {
        blade3_body
      }
    }
    blade3_title
    blade4_body {
      data {
        blade4_body
      }
    }
    blade4_title
    blade5_body {
      data {
        blade5_body
      }
    }
    blade5_title
    blade6_body {
      data {
        blade6_body
      }
    }
    blade6_title
    blade7_body {
      data {
        blade7_body
      }
    }
    blade7_title
    deadline
    page_seo_description
    page_sub_title
    page_title
    register_cta
    thank_you_for_applying {
      data {
        thank_you_for_applying
      }
    }
    tooltip_content
    tooltip_heading
    already_registered
  }
  strapiHackathonRule {
    page_body {
      data {
        page_body
      }
    }
    page_headline {
      data {
        page_headline
      }
    }
    page_seo_description
    page_seo_title
  }
  strapiHack0AnnouncementsPage {
    page_seo_description
    page_seo_title
  }
  strapiHack0AnnouncementsPage {
    page_seo_description
    page_seo_title
  }
  strapiHack0ResourcesPage {
    page_seo_description
    page_seo_title
  }
  strapiHack0SubmissionsPage {
    page_seo_description
    page_seo_title
  }
  allStrapiHack0Announcement {
    nodes {
      body {
        data {
          body
        }
      }
      date(fromNow: true)
      order
      title
    }
  }
  allStrapiHack0Resource {
    nodes {
      title
      content {
        data {
          content
        }
      }
      order
    }
  }
  strapiCommonContent {
    hack0_submissions
  }
  strapiHack0GalleryPage {
    enabled
  }
}
`;
